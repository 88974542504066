<template>
	<modal-wrapper @close="close">
		<div class="dialog">
			<dlg-header title="Rename" @close="close" />
			<div style="padding: 16px;">
				<p>
					This will internally rename the study in Keystone. DICOM tags will
					<i>not</i>
					be modified unless you export the study out of Keystone.
				</p>
				<form @submit.prevent>
					<ast-button @click.native="selectStudy">
						Copy values from another study &hellip;
					</ast-button>
					<div class="form-group">
						<ast-input v-model.trim="form.patientName" label="Patient Name" class="form-group" maxlength="100" />
						<ast-input v-model.trim="form.patientId" label="Patient ID" class="form-group patient-id" maxlength="100" />
						<ast-input v-model.trim="form.ownerName" label="Owner Name" class="form-group" maxlength="100" />
						<ast-input v-model.trim="form.studyDescription" label="Description" class="form-group" maxlength="254" />
						<ast-input
							v-model.trim="form.accessionNumber"
							label="Accession Number"
							class="form-group accession-number"
							maxlength="50"
						/>
						<ast-input
							v-model.trim="form.institutionName"
							label="Institution Name"
							class="form-group"
							maxlength="100"
						/>
						<ast-input
							v-model.trim="form.referringPhysiciansName"
							label="Referring Physician"
							class="form-group"
							maxlength="75"
						/>
					</div>

					<!-- CHECKBOXES -->
					<div class="form-group">
						<ast-checkbox v-model="form.renameReports">
							Rename the patient on all reports associated with this study
						</ast-checkbox>
					</div>

					<p v-if="reservedNameInUse" class="is-danger">
						{{ reservedNameInUse }} is reserved for internal use. Please enter a different name.
					</p>

					<ast-button type="primary" :class="{ disabled: !canSubmitForm }" @click.native="submit">
						Save Changes
					</ast-button>
					<ast-button @click.native="close">Cancel</ast-button>
				</form>
			</div>
		</div>
	</modal-wrapper>
</template>

<script>
import AstButton from '@components/Button'
import AstInput from '@components/Input'
import AstCheckbox from '@components/Checkbox'
import DlgHeader from '@components/DlgHeader'
import ModalWrapper from '@components/ModalWrapper.vue'
import api from '@services/api'
import { dlg } from '@utils/dlgUtils'
import { openStudiesDlg } from '@dialogs/StudiesDlg.vue'

const RenameStudyDlg = {
	name: 'RenameStudyForm',
	components: {
		AstButton,
		AstInput,
		AstCheckbox,
		DlgHeader,
		ModalWrapper,
	},
	props: {
		study: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			isSubmitting: false,
			form: {
				patientName: '',
				patientId: '',
				ownerName: '',
				studyDescription: '',
				accessionNumber: '',
				institutionName: '',
				referringPhysiciansName: '',
				renameReports: false,
			},
		}
	},
	computed: {
		reservedNameInUse() {
			const reservedNames = ['DELETE', 'TEST', 'DAILYQA', 'DAILY QA', 'TEST MEASURE', 'QA']
			if (reservedNames.includes(this.form.ownerName)) return this.form.ownerName
			if (reservedNames.includes(this.form.patientName)) return this.form.patientName
			return null
		},
		canSubmitForm() {
			if (this.isSubmitting) return false
			if (this.reservedNameInUse) return false

			const requiredFields = ['ownerName', 'patientId', 'patientName']
			if (requiredFields.some(field => !this.form[field])) return false
			return true
		},
	},
	created() {
		this.copyFromStudy(this.study)
	},
	methods: {
		async selectStudy() {
			const study = await openStudiesDlg({ excludeStudyIds: [this.study.studyId] })
			if (!study) return
			this.copyFromStudy(study)
		},
		copyFromStudy(study) {
			for (const key in this.form) {
				if (key in study) this.form[key] = study[key]
			}
		},
		async close() {
			dlg.close(this, true)
		},
		async submit() {
			if (!this.canSubmitForm) return
			this.isSubmitting = true
			try {
				await api.study.renameStudy({
					studyId: this.study.studyId,
					studyInstanceUid: this.study.studyInstanceUid,
					batchId: this.study.batchId,
					...this.form,
				})
				dlg.close(this, true, this.form)
			} finally {
				this.isSubmitting = false
			}
		},
	},
}

export default RenameStudyDlg

export function openRenameStudyDlg(study) {
	return dlg.open(RenameStudyDlg, { study })
}
</script>

<style lang="scss" scoped>
.dialog {
	padding: 0;
}
form,
button,
.form-group {
	margin: 16px 0;
}
form {
	margin-bottom: 0;
}

button + button {
	margin-left: 5px;
}

.form-group {
	max-width: 500px;
	&.patient-id,
	&.accession-number {
		max-width: 200px;
	}
}
</style>
