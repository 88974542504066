<template>
	<li :title="upload.file.name">
		<div class="left-side" :class="{ 'with-slot': $slots.default }">
			<svg-icon :icon="icon" :class="iconClass" fixed :pulse="icon === 'spinner'" />
			<div class="details">
				<p :class="{ 'is-danger': isError }">{{ upload.file.name }}</p>
				<p class="file-info">
					<span v-if="upload.isInvalid" class="error-message">
						<template v-if="upload.isImage">
							<slot name="jpeg-error">
								JPEG and PNG images are not allowed.
							</slot>
						</template>
						<template v-else-if="upload.parseWarning">
							{{ upload.parseWarning }}
						</template>
						<template v-else-if="upload.isDicom">
							Please use the Import Studies dialog to import DICOM files.
						</template>
						<template v-else-if="!upload.isAttachment">
							This file does not appear to be a valid DICOM image.
						</template>
						<template v-else>
							This file type is not allowed.
						</template>
					</span>
					<span v-if="isError" class="error-message">
						<span v-if="upload.isFailedUpload">
							Upload failed.
							<template v-if="!errorMessage">
								Please check your network connection and try again later.
							</template>
						</span>
						{{ errorMessage }}
					</span>
					<span v-if="!isError && !upload.isInvalid">
						{{ upload.file.size | fileSize }}
					</span>
				</p>
			</div>
		</div>
		<div class="slot-container">
			<slot></slot>
		</div>
		<button v-if="showCancel" class="cancel btn" title="Remove file" @click="$emit('cancel')">
			<svg-icon icon="close" />
		</button>
	</li>
</template>

<script>
export default {
	name: 'UploadItemFile',
	props: {
		upload: {
			type: Object,
			required: true,
		},
		errorMessage: {
			type: String,
			default: '',
		},
		showCancel: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			imageViews: [],
		}
	},
	computed: {
		isError() {
			return this.upload.isFailedUpload || this.errorMessage
		},
		icon() {
			if (this.upload.isInvalid) return 'exclamation-triangle'
			if (this.isError) return 'exclamation-circle'
			if (this.upload.isUploading) return 'spinner'
			if (this.upload.isUploaded) return 'check-circle'
			return 'clock-o'
		},
		iconClass() {
			if (this.upload.isInvalid) return 'is-warning'
			if (this.isError) return 'is-danger'
			if (this.upload.isUploading) return 'is-muted'
			if (this.upload.isUploaded) return 'is-success'
			return 'is-muted'
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';
li {
	display: flex;
	position: relative;
	align-items: center;
	background: var(--secondary-bg);
	border-bottom: 1px solid var(--secondary-border);
	padding: 2px 16px;
	transition: all 0.1s ease;
	overflow: hidden;
	@media (max-width: $mqMedium) {
		flex-wrap: wrap;
	}
	& > * {
		margin: 8px;
	}
	.left-side {
		display: flex;
		align-items: center;
		overflow: hidden;
		&.with-slot {
			width: 40%;
		}
		.details {
			overflow: hidden;
			flex-grow: 1;
		}
		p {
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.is-danger {
			font-weight: 400;
		}
		.error-message {
			font-style: italic;
		}
		.icon {
			font-size: 1.25em;
			margin-right: 16px;
		}
		.file-info {
			font-size: 0.75em;
		}
	}
	.slot-container {
		margin-right: 52px;
		flex-grow: 1;
	}
	button.cancel {
		position: absolute;
		top: 0;
		right: 0;
		padding: 16px;
		margin: 0;
		background: transparent;
		border: 0;
		&:hover {
			.icon {
				opacity: 1;
			}
		}
	}
}
</style>
