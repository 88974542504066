<template>
	<div class="upload-item-batch">
		<!-- Batch Study details -->
		<study-card
			v-if="!onlyShowFiles"
			:icon="icon"
			:icon-class="iconClass"
			:show-close-button="showCloseButton && !!study.studyInstanceUid"
			:show-details="!!study.studyInstanceUid"
			:study="study"
			:uploads="uploads"
			:progress-status="isUploading ? 'Uploading' : isProcessing ? 'Processing' : null"
			:progress-percent="isUploading ? uploadPercent : isProcessing ? processingPercent : null"
			class="upload-card"
			:class="{ 'no-hover-effect': !study.studyInstanceUid || isSingleAttachment }"
			@close="removeBatch"
			@click.native="isExpanded = !isExpanded"
		>
			<div class="upload-buttons">
				<!-- Rename Button -->
				<button
					v-if="showRenameButton && !isError && !isDeleted"
					class="btn btn-default"
					:class="{ 'not-ready': study.isDicom && !status.study }"
					@click.stop="renameBatch"
				>
					<svg-icon icon="edit" style="margin-right: 4px;" />
					Rename
				</button>
				<!-- View Button -->
				<button
					v-if="showViewButton && isComplete && !isDeleted"
					class="btn btn-default"
					@click.stop="openStudy({ studyId: status.study.studyId })"
				>
					<svg-icon icon="eye" style="margin-right: 4px;" />
					View
				</button>
			</div>
			<slot></slot>
			<!-- Error -->
			<p v-if="!isProcessing && isError" class="upload-status is-danger">
				<strong>One or more files failed to import.</strong>
			</p>
			<!-- JPEG form -->
			<ast-upload-study-form
				v-else-if="!study.studyInstanceUid"
				:context="study.context"
				@expand="isExpanded = !isExpanded"
			/>
		</study-card>

		<!-- Uploads in batch -->
		<transition name="expand">
			<p v-if="onlyShowFiles && isProcessing" style="margin-bottom: 15px;">
				<svg-icon icon="spinner" pulse fixed /> Processing files &hellip;
			</p>
		</transition>
		<transition name="expand">
			<div
				v-if="(isExpanded && !isSingleAttachment) || onlyShowFiles"
				class="uploads"
				:class="{ 'only-files': onlyShowFiles }"
			>
				<ol>
					<li
						is="AstUploadItemFile"
						v-for="(upload, i) in sortedUploads"
						:key="i"
						:error-message="getErrorMessageForUpload(upload.sopInstanceUid)"
						:upload="upload"
						:show-cancel="onlyShowFiles"
						@cancel="removeFile(upload)"
					/>
				</ol>
			</div>
		</transition>
	</div>
</template>

<script>
import AstUploadItemFile from '@components/UploadItemFile.vue'
import StudyCard from '@components/StudyCard.vue'
import { openRenameStudyDlg } from '@dialogs/RenameStudy.vue'
import { showAlert } from '@dialogs/MessageDlg.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { mapActions } from 'vuex'
import { uploadData } from '@services/uploads'

export default {
	name: 'UploadItemBatch',
	components: {
		AstUploadItemFile,
		StudyCard,
		AstUploadStudyForm: () => import(/* webpackChunkName: "componentUploadStudyForm" */ '@components/UploadStudyForm'),
	},
	props: {
		uploads: {
			type: Array,
			default: () => [],
		},
		showCloseButton: {
			type: Boolean,
			default: true,
		},
		showRenameButton: {
			type: Boolean,
			default: false,
		},
		showViewButton: {
			type: Boolean,
			default: false,
		},
		onlyShowFiles: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			uploadData,
			isExpanded: false,
			status: {},
			pollTimer: undefined,
		}
	},
	computed: {
		study() {
			if (!this.uploads.length) return {}
			return {
				...this.uploads[0], // grab study metadata from first upload
			}
		},
		sortedUploads() {
			let set = new Set(this.status.errorMessages.map(e => e.sopInstanceUid))

			return [...this.uploads].sort(function(a, b) {
				let aHasErrors = a.isFailedUpload || set.has(a.sopInstanceUid)
				let bHasErrors = b.isFailedUpload || set.has(b.sopInstanceUid)
				if (aHasErrors && !bHasErrors) return -1
				else if (!aHasErrors && bHasErrors) return 1
				else return 0
			})
		},
		uploadPercent() {
			const done = this.uploads.filter(upload => upload.isUploaded).length
			const didNotFail = upload => !upload.isFailedUpload
			const total = this.uploads.filter(didNotFail).length
			return Math.round((done / total) * 100)
		},
		processingPercent() {
			if (!this.isProcessing) return 0
			return Math.round((this.status.completeCount / this.status.fileCount) * 100)
		},
		isUploading() {
			return this.uploads.some(upload => upload.isUploading)
		},
		isError() {
			return this.uploads.some(upload => upload.isFailedUpload) || this.status.errorCount
		},
		isComplete() {
			return this.uploadPercent === 100 && !this.isError && this.status.completeCount === this.status.fileCount
		},
		isDeleted() {
			return this.isComplete && !this.status.study
		},
		isProcessing() {
			return this.uploadPercent === 100 && !this.isError && this.status.completeCount !== this.status.fileCount
		},
		isSingleAttachment() {
			return this.uploads.length === 1 && this.uploads[0].isAttachment
		},
		icon() {
			if (this.isUploading) return 'spinner'
			if (this.isProcessing) return 'spinner'
			if (this.isError) return 'exclamation-circle'
			if (this.isComplete) return 'check-circle'
			return 'clock-o'
		},
		iconClass() {
			if (this.isUploading) return 'is-muted'
			if (this.isProcessing) return 'is-muted'
			if (this.isError) return 'is-danger'
			if (this.isComplete) return 'is-success'
			return 'is-muted'
		},
	},
	watch: {
		'status.study': function() {
			if (!this.isComplete) return
			if (!this.status.study) return
			const emitData = {
				batchId: this.study.batchId,
				studyId: this.status.study.studyId,
			}
			if (this.isSingleAttachment) {
				emitData.imageId = this.status.study.imageIds[0]
				emitData.reportTemplateImageViewId = this.uploads[0].reportTemplateImageViewId
			}
			this.$emit('study-complete', emitData)
		},
		isError() {
			// expand files when error occurs so the user can see the file-specific errors
			if (this.isError) this.isExpanded = true
		},
	},
	mounted() {
		this.getStatus()
		this.pollTimer = setInterval(this.getStatus, 2000)
	},
	destroyed() {
		clearInterval(this.pollTimer)
	},
	methods: {
		...mapActions(['openStudy']),
		async getStatus() {
			if (!this.study.batchId) return
			try {
				this.status = await uploadData.getUploadStatus(this.study.batchId)
				const allUploadsFailed = this.uploads.every(upload => upload.isFailedUpload)
				const isDoneProcessing = !this.isProcessing && this.uploadPercent === 100
				const canStopPolling = allUploadsFailed || isDoneProcessing
				if (canStopPolling) clearInterval(this.pollTimer)
			} finally {
			}
		},
		async removeBatch() {
			if (!this.isUploading) {
				this.$emit('remove-batch', { ...this.study, ...this.status.study })
			}
			if (this.isUploading && (await showConfirm('Are you sure you want to cancel this upload?'))) {
				uploadData.stopUploads(this.study)
			}
		},
		async removeFile(upload) {
			if (!this.isUploading) {
				this.$emit('remove-file', { ...upload, ...this.status.study })
			}
			if (this.isUploading && (await showConfirm('Are you sure you want to cancel this upload?'))) {
				uploadData.stopUploads({ sopInstanceUid: upload.sopInstanceUid })
			}
		},
		async renameBatch() {
			if (this.study.isDicom && !this.status.study) {
				const warning =
					'The study may not be renamed until at least one file has been uploaded and processed.  Please try again shortly.'
				showAlert(warning)
				return
			}
			// get rename form values from API in case study already exists and was renamed before
			let form = await uploadData.getUploadRenameForm(this.study.studyInstanceUid)
			// if study has not been created yet, populate rename form with batch metadata
			if (!form.studyId) form = this.study
			const renamedStudy = await openRenameStudyDlg(form)
			if (!renamedStudy) return
			// update uploads
			this.uploads.forEach(u => {
				u.patientId = renamedStudy.patientId
				u.patientName = renamedStudy.patientName
				u.ownerName = renamedStudy.ownerName
				u.accessionNumber = renamedStudy.accessionNumber
				u.institutionName = renamedStudy.institutionName
				u.referringPhysiciansName = renamedStudy.referringPhysiciansName
				u.studyDescription = renamedStudy.studyDescription
			})
		},
		getErrorMessageForUpload(sopInstanceUid) {
			if (!sopInstanceUid || !this.status.errorMessages) return
			const isForThisUpload = error => error.sopInstanceUid === sopInstanceUid
			const errorForUpload = this.status.errorMessages.find(isForThisUpload)
			if (errorForUpload) return errorForUpload.errorMessage
		},
	},
}
</script>

<style lang="scss">
@import '~@styles/_vars.scss';
.upload-item-batch {
	margin: 15px 0;
	max-width: 1000px;
	.upload-card {
		background: var(--secondary-bg);
	}
	.uploads li:first-child {
		border-top: 1px solid var(--secondary-border);
	}
	.upload-card:not(.no-hover-effect) {
		cursor: pointer;
	}
	.file-summary {
		text-align: center;
		padding: 5px;
		cursor: pointer;
	}
	.uploads {
		padding: 0 15px;
		&.only-files {
			padding: 0;
		}

		li {
			opacity: 0.75;
		}
	}
	.upload-buttons {
		display: flex;
		button {
			margin-top: 16px;
		}
		button + button {
			margin-left: 8px;
		}
		.not-ready {
			opacity: 0.5;
			cursor: wait;
		}
	}
	.upload-buttons,
	.upload-status {
		margin-left: 40px;
	}
}
</style>
